import React from "react"
import { graphql } from "gatsby"
import BuyersGuide from "../../containers/RealestatePage1/Properties/BuyersGuide"
import Headermenu from "../../containers/RealestatePage1/HeaderMenuBS"
import Footer from "../../containers/RealestatePage1/Footer"
import GlobalStyle from "../../containers/RealestatePage1/Common/global-styles"
import Properties from "../../containers/RealestatePage1/Properties"
import {
  PageHeading,
  HeadingDescription,
} from "../../containers/RealestatePage1/Properties/properties.style"
import Fonts from "../../containers/RealestatePage1/Common/fonts"
import "../../components/layout.css"
import SEO from "../../components/seo"

function index({ data }) {
  console.log(data)
  const {
    pageHeading,
    headingDescription,
  } = data.realestatePage1Json.Properties
  return (
    <>
      <Fonts />
      <GlobalStyle />
      <main>
        <SEO title="Properties" description="Get Your Dream Home With Us" />
        <Headermenu home={false} />
        <div style={{ marginTop: 90 }}>
          {/* <PageHeading>{pageHeading}</PageHeading>
          <HeadingDescription>{headingDescription}</HeadingDescription> */}
        </div>
        <Properties />
        <BuyersGuide />
      </main>
      <Footer />
    </>
  )
}
export const query = graphql`
  query MyQuery {
    realestatePage1Json {
      Properties {
        pageHeading
        headingDescription
      }
    }
  }
`

export default index
