import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { contentWrapper, content } from "../Common/markdownStyle.module.css"
import { Container } from "react-bootstrap"
function BuyersGuide() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { title: { eq: "buyersGuide" } }) {
        html
      }
    }
  `)
  const { html } = data.markdownRemark
  return (
    <div className={contentWrapper}>
      <Container>
        <article
          className={content}
          dangerouslySetInnerHTML={{ __html: html }}
        ></article>
      </Container>
    </div>
  )
}

export default BuyersGuide
